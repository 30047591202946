import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";

const NotFoundPage: React.FC<PageProps> = () => {
  const picture = useStaticQuery(graphql`
    query {
      picture: file(relativePath: { eq: "images/404.png" }) {
        childImageSharp {
          fluid(maxWidth: 820) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <div className='w-full'>
        <div className='block mx-auto'>
          <Img fluid={picture.picture.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
